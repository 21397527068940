.testimonial-container{
    width: 100%;
    height: 100%;
    padding: 2rem;
}

.testimonial-container h2{
    font-size: 2.2rem;
    font-weight: 600;
    margin-top: 20px;
}

/* .testimonial-container h2 hr{
    width: 20%;
    border: none;
    outline: none;
    height: 4px;
    background-color: rgb(190, 190, 61);
} */

.testimonial-container .testimonial{
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1rem 2rem;
}
.testimonial-container .testimonial .swiper {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 20px;
  }
  
  .testimonial-container .testimonial .swiper .swiper-slide {
    text-align: center;
    font-size: 16px;
    width: 300px;
    height: 300px;
    background: #fff;
  
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .testimonial-container .testimonial .swiper .swiper-slide .client{
    width: 100%;
    height:100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding: 1rem 2rem;
    /* From https://css.glass */
background: rgba(255, 255, 255, 0.289);
border-radius: 16px;
box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
backdrop-filter: blur(5px);
-webkit-backdrop-filter: blur(5px);
border: 1px solid rgba(255, 255, 255, 0.34);
  }
  .testimonial-container .testimonial .swiper .swiper-slide .client .client-img{
    width:30%;
    border-radius: 50%;
  }

  .testimonial-container .testimonial .swiper .swiper-slide .client .client-text{
    width:70%;
  }



  .testimonial-container .testimonial .swiper .swiper-slide .client .client-img img{
    width: 180px;
    height:180px;
    border-radius: 50%;
    object-position: center;
    object-fit: cover;
  }

  /* .testimonial-container .testimonial .swiper .swiper-slide .client .client-text{
    width: 50%;

  } */

  .testimonial-container .testimonial .swiper .swiper-slide .client .client-text p{
    font-size: 1rem ;
    font-weight: 500;
    line-height: 1.2;
    margin-bottom: 20px;
  }

  .testimonial-container .testimonial .swiper .swiper-slide .client .client-text h2{
    font-size: 2rem;
    font-weight: 500;
  }

  @media screen and (max-width:767px) {

    .testimonial-container{
      max-width: 1920px;
      width: 100%;
      height:100%;
  }
  

    .testimonial-container .testimonial .swiper .swiper-slide {
    
      height: 300px;
    }

    .testimonial-container h2 {
      font-size: 1.5rem;
  }


  .testimonial-container .testimonial .swiper .swiper-slide .client{
    width: 100%;
    height:100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 10px;
    padding:0px;

  }

  .testimonial-container .testimonial .swiper .swiper-slide .client .client-img{
    display: flex;
    align-items: center;
    justify-content: center;
    width:100%;
    border-radius: 50%;
  }

  .testimonial-container .testimonial .swiper .swiper-slide .client .client-img img{
   
    width: 100px;
    height: 100px;
  }

  .testimonial-container .testimonial .swiper .swiper-slide .client .client-text p {
    font-size: 12px;
    line-height: 1.2;
    margin-bottom: -10px;
}

.testimonial-container .testimonial .swiper .swiper-slide .client .client-text h2 {
  font-size: 1.4rem;
  font-weight: 500;
}

  
  }