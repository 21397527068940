
  .slider{
    max-width: 1900px;
    width: 100%;
   height:90vh;
  }
  .swiper {
    width: 100%;
    height: 100%;
  }
  
  .swiper-slide {
    width: 100%;
    height: 100%;
    text-align: center;
    font-size: 18px;
    background: #c59292;
    /* Center slide text vertically */
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    
  }
  
  .swiper-slide img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit:cover;
    object-position:center;
  }

  .banner-detail{
    position: absolute;
    top:50%;
    margin: 0 auto;
    width:100%;
    text-align: center;
    left:0%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap:10px;
    padding: 0 2rem;
    margin-top: -100px;
  }

  .banner-detail h2{
    font-size: 4rem;
    color: white;
    font-weight: 800;
  }

  .banner-detail p{
    font-size:2rem;
    color: white;
    font-weight: 800;
  }

  @media screen and (max-width:767px) {
    .slider {
      height: 35vh;
  }
  .swiper-slide img {
    object-fit:fill;
  }
  
  .banner-detail{
    margin-top: -60px;
  }

  .banner-detail h2{
    font-size:24px;
  }

  .banner-detail p{
    font-size:14px;
  }

  }


  