.footer-container{
    max-width: 1920px;
    width: 100%;
    height:100%;
  }
  
  .footer {
      background: black;
    }
    .footer .footer-row {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      gap: 2.5rem;
      padding: 60px;
    }
    .footer-row .footer-col h4 {
      color: #fff;
      font-size: 1.2rem;
      font-weight: 400;
    }
    .footer-col .links {
      margin-top: 20px;
    }
    .footer-col .links li {
      list-style: none;
      margin-bottom: 10px;
    }
    .footer-col .links li a {
      text-decoration: none;
      color: #bfbfbf;
    }
    .footer-col .links li a:hover {
      color: #fff;
    }
    .footer-col p {
      margin: 20px 0;
      color: #bfbfbf;
      max-width: 300px;
    }
    .footer-col form {
      display: flex;
      gap: 5px;
    }
    .footer-col input {
      height: 40px;
      border-radius: 6px;
      background: none;
      width: 100%;
      outline: none;
      border: 1px solid #7489C6 ;
      caret-color: #fff;
      color: #fff;
      padding-left: 10px;
    }
    .footer-col input::placeholder {
      color: #ccc;
    }
     .footer-col form button {
      background: #fff;
      outline: none;
      border: none;
      padding: 5px 10px;
      border-radius: 6px;
      cursor: pointer;
      font-weight: 500;
      transition: 0.2s ease;
    }
    .footer-col form button:hover {
      background: #cecccc;
    }
    .footer-col .icons {
      display: flex;
      margin-top: 30px;
      gap: 30px;
      cursor: pointer;
    }
    .footer-col .icons i {
      font-size: 2.5rem;
      color: #afb6c7;
    }
    .footer-col .icons i:hover  {
      color: #fff;
    }
  
    .footer-col img{
      width: 300px;
      height: 100px;
      border: 2px solid white;
    }
  
    .company{
      font-size: 2rem;
    }
  
    .copyright{
      background-color: rgb(24, 24, 24);
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%;
      height: 10vh;
    }
  
    .copyright h2{
      font-size: 1.7rem;
      color: white;
    }
  
    .copyright h2 span a{
      color: rgb(61, 167, 57);
      letter-spacing:4px;
    }
    @media (max-width: 768px) {
      .footer {
        position: relative;
        bottom: 0;
        left: 0;
        transform: none;
        width: 100%;
        border-radius: 0;
      }
      .footer .footer-row {
        padding: 20px;
        gap: 1rem;
      }
      .footer-col form {
        display: block;
      }
      .footer-col form :where(input, button) {
        width: 100%;
        margin-bottom: 10px;
      }
  
      .copyright h2 {
        font-size: 18px;
        color: white;
    }
  }