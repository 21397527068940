.nav-links li a{
    font-variant: small-caps;
    position: relative;
}

.hidden{
    display: none!important;
}
.visible{
    display: flex!important;
}

.slng-dropdown{
    position: relative;
}

.dropdown{
    width: 240px;
    position: absolute;
    top:35px;
    left: 0px;
    z-index: 999;
    background-color:black;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    display: none;
    flex-direction: column;
    height: 100px;
    color:white ;
    font-size: 1.4rem;
}

.dropdown li{
    height: 40%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.dropdown li a{
    color: var(--text-color);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    padding-left: 16px;
}

.dropdown li a:hover{
    background-color: #6699b2;
}

.nav-links li a::after{
    content: '';
    width: 0%;
    left: 0%;
    bottom:0%;
    background-color: rgb(18, 174, 18);
    position: absolute;
    height:2px;
    border: none;
    border-radius:20px;
    transition: all 0.3s ease-in-out;
}

.nav-links li a:hover::after{
    width: 100%;
}
.nav-links li a{
   font-size: 1.4rem;
}

.responsive{
    display:none;
}

.click{
    right:0 !important;
}

@media screen and (max-width:767px) {

    .responsive{
        display: block;
        font-size: 1.4rem;
    }
    .nav-links {
        flex-direction: column;
        gap: 15px;
        z-index: 9;
        position: absolute;
        top:10%;
        right:100%;
        padding: 20px;
        width: 100%;
        background:#000000;
        transition: all 0.4s ease-in-out;
    }

    .dropdown {
        width: 207px;
        top: 33px;
        left: -15px;
    }
    
}