
.heading h2{
    font-size:3rem;
    font-weight: 700;
    font-variant: small-caps;
    text-align: center;
    letter-spacing: 5px;
    margin-bottom: 20px;
}

.heading span{
    color: rgb(132, 162, 102);
}

.products{
    display: grid;
    padding:2rem;
    grid-template-columns: repeat(3,1fr);
    align-items: center;
    justify-content: center;
    gap: 40px;
}

.products .product{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.products .product .product-img{
    width: 250px;
    height:250px;
    border-radius: 50px;
    /* box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset; */
}

.products .product .product-img img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
    border-radius: 50px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px, rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.products .product .product-text h4{
  font-size:2rem;
  margin-top:-30px;
  color:white;
  font-weight:700;
  font-variant: small-caps;
  text-shadow: 1px 1px 1px #b3c9a5, 
               3px 3px 5px rgb(14, 14, 14); 
}

@media screen and (max-width:767px) {
    .products{
        grid-template-columns: repeat(1,1fr);
    }
    
    .heading h2{
        font-size:2rem;
    }
}