.contact-container{
    width: 100%;
    height: 100%;
    padding: 2rem;
}

.contact{
    display: flex;
    align-items: center;
    justify-content: center;
}

.contact-left{
    width:70%;
    height: 100%;
    padding: 2rem;
}

.head-drop h2{
    font-size: 2rem;
    font-weight: 700;
    margin-left: 30px;
    font-variant: small-caps;
}

.form{
    padding:0px 30px ;
}

.form form{
    display: flex;
    flex-direction: column;
    gap: 20px;
    margin-top: 20px;
}

.input-flex{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
}


.input-flex input{
    width: 50%;
}


form p .form-style {
    width: 100%;
    height:40px;
    padding: 1rem;
    border: none;
    border-radius:5px;
    box-shadow: 1px 1px 2px;
}

form p textarea{
    width: 100%;
    padding: 1rem;
    border: none;
    border-radius:5px;
    box-shadow: 1px 1px 2px;
}


.submit{
    font-size: 1.4rem;
    font-weight: 600;
    color: white;
    background-color: green;
    padding:5px 20px;
    border-radius:10px;
    text-transform: uppercase;
}

.contact-right{
    width:30%;
}


.detail{
    display: flex;
    margin-bottom:40px;
}

.detail ul{
    display: flex;
    flex-direction: column;
    gap:40px;
}

.detail ul li{
    display: flex;
    align-items: center;
    gap:20px;
}

.detail ul li i{
    font-size: 2rem;
    font-weight: 700;
    color: green;
}

.detail ul li p{
    font-size: 20px;
}

@media screen and (max-width:767px) {
    .contact-container{
        padding:0rem;
    }
    
    .contact{
        flex-direction: column;
        gap:30px;
    }
    
    .contact-left{
        width:100%;
        padding:0rem;
    }
    
    .head-drop h2{
        font-size:1.6rem;
        text-align: center;
        margin-left: 30px;
    }
    
    .form{
        padding:0px 20px ;
    }
    
    .form form{
        gap:10px;
    }
    
    .input-flex{
      flex-direction: column;
        gap: 10px;
    }
    
    
    .input-flex input{
        width:100%;
    }
    
    
    form p textarea{
        height:100px;
    }
    
    
    .submit{
        font-size: 1.2rem;
    }
    
    .contact-right{
        width:100%;
    }
    
    
    .detail{
        display: flex;
        padding:0px 20px ;
        margin-bottom:40px;
    }
    
    .detail ul{
        gap:20px;
    }
    
    .detail ul li{
        display: flex;
        align-items: center;
        gap:20px;
    }
    
    .detail ul li i{
        font-size: 2rem;
        font-weight: 700;
        color: green;
    }
    
    .detail ul li p{
        font-size: 1rem;
    }
}

