

.welcome{
    padding: 0px 20px ;
}

.welcome h2{
    font-size:3rem;
    font-weight: 600;
    font-variant: small-caps;
    letter-spacing: 5px;
    margin-bottom: 20px
}

.welcome span{
    color:rgb(97 159 36);
}

.welcome p{
    font-size: 18px;
}

.about{
    display: grid;
    grid-template-columns: repeat(2,1fr);
    align-items: center;
    justify-content: center;
}

.right{
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
}

.right h2{
    font-size: 1.9rem;
    font-weight: bolder;
}

.right p{
    font-size: 18px;
    text-overflow: ellipsis;
}

.btn{
    padding: 2px 6px;
    font-weight: bold;
    font-size: 18px;
    color: white;
    background:green;
    border-radius:14px 0px 14px 0px;
}

.left{
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.image {
    width: 350px;
    height: 350px;
    position: relative;
    border: 5px solid green;
    border-radius: 100px 0px 100px 0px;
}

.image img{
    position: absolute;
    top:-30px;
    left: 40px;
    width: 350px;
    height: 350px;
    object-fit: cover;
    object-position: center;
    border-radius: 100px 0px 100px 0px;
}

@media screen and (max-width:767px) {

    .about-section{
        padding: 1rem;
    }
    
.welcome{
    padding: 0px;
    width: 100%;
}

    
    .welcome h2{
        font-size:1.5rem;
        margin-bottom: 10px;
    }
    
    .welcome p{
        font-size: 18px;
    }
    
    .about{
        grid-template-columns: repeat(1,1fr);
        gap: 50px;
    }
    
    
   
    .right{
        align-items: center;
    }
    
    .right h2{
        font-size: 1.9rem;
        font-weight: bolder;
    }
    
    .right p{
        font-size: 15px;
        text-align: center;
    }
    
    
    .image {
        width:300px;
        height:300px;
        position: relative;
        border: 5px solid green;
        border-radius: 100px 0px 100px 0px;
    }
    
    .image img{
        position: absolute;
        top:-30px;
        width: 300px;
        height: 300px;
    }
  }


